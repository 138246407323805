<template>
    <v-main>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="cookies"
                    tag="section"
                >
                    <div style="text-align: center">
                        <img
                            src="/img/LogoLifePlus.png"
                            alt=""
                            style="width: 100px"
                        >
                    </div>

                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            style="max-width: 978px"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                            >
                                <div class="d-flex grow flex-wrap">
                                    <v-card-text>
                                        <h2 class="mt-5 mb-10 black--text text-uppercase text-center">
                                            Информация о файлах cookie
                                        </h2>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            Что такое файлы cookie?
                                        </h4>
                                        <p>
                                            Файлы cookie — это небольшие файлы данных, которые посещенный вами сайт может записать на ваш жесткий диск. Такие файлы обычно содержат уникальный анонимный идентификатор. Файл cookie не может считывать данные с вашего жесткого диска или переносить компьютерные вирусы.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            Какие файлы cookie и аналогичные технологии использует lifeplus?
                                        </h4>
                                        <p>
                                            lifeplus устанавливает файлы сookie и схожие технологии для обеспечения таких функций, как идентификация пользователей, понимание персональных особенностей использования Сервиса.
                                            В некоторых случаях, описанных ниже, представители третьей стороны также получают возможность устанавливать файлы сookies.
                                            В этом документе мы описываем файлы cookie и похожие технологии, используемые на нашем сайте.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            Функциональные файлы cookie
                                        </h4>
                                        <p>
                                            Сookie-файлы необходимы для корректной работы Сервиса и его функций.
                                            Например, такие файлы помогают узнавать и запоминать вас при входе в Сервис,
                                            чтобы мы могли учитывать ваши настройки и предпочтения, такие как язык и регион или ваше состояние авторизации.
                                            Кроме того, эти сookie позволяют предоставлять вам запрашиваемые вами сервисы,
                                            такие как реферальные программы, страницы-визитки и другие подобные инструменты.
                                            Эти файлы не отслеживают вашу деятельность в сети Интернет за пределами сайтов lifeplus.
                                        </p>
                                        <p>
                                            Вы можете отключить любой из этих функциональных сookie-файлов, но в таком случае некоторые функции Сервиса могут стать недоступными или не функционировать должным образом.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            Аналитика
                                        </h4>
                                        <p>
                                            Мы используем файлы cookie и похожие технологии, которые помогают нам понять, как посетители взаимодействуют с нашим сайтом,
                                            мобильными приложениями, клиентами для персональных компьютеров. Передавая нам информацию о том, как вы используете наш Сервис, эти инструменты помогают нам понять,
                                            как сделать lifeplus лучше и настроить взаимодействие с пользователями. Например, эти технологии могут сообщить нам, откуда вы пришли на сайт lifeplus,
                                            посещали ли вы его ранее, как долго вы находитесь на сайте и какие страницы посещали. Кроме того, он может дать нам общую информацию о том,
                                            в какой стране находится пользователь.
                                        </p>
                                        <p>
                                            Мы используем следующие аналитические инструменты: Google Analytics и Яндекс Метрика
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            Рекламирование
                                        </h4>
                                        <p>
                                            Мы получаем уникальный рекламный идентификатор (Рекламный идентификатор или "IDFA" на устройствах Apple,
                                            а также Рекламный Google ID или "AID" и Yandex ID или "UID" на устройствах Android) с вашего устройства.
                                            Эти идентификаторы уникальны для вашего устройства, но вы можете сбросить их или ограничить их использование в настройках устройства. Эти идентификаторы не содержат вашего имени или адреса электронной почты. Мы используем эти идентификаторы с нашими инструментами для аналитики в целях совершенствования нашей целевой рекламы.
                                        </p>
                                        <p>
                                            Мы взаимодействуем со сторонними рекламными сетями, применяющими файлы cookie и аналогичные технологии для сбора информации о ваших посещениях определенных страниц liferecover.ru и использовании инструментов lifeplus. В процессе этого мы не делимся с третьими сторонами никакой информацией о вашем аккаунте (адресом электронной почты или именем) или содержимым вашего аккаунта.
                                        </p>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
export default {
name: "Cookies"
}
</script>

<style scoped>

</style>